<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="white"
    opacity="0.50"
  >
    <b-card-actions
      ref="agentsListActionCard"
      :title="'Sales Advisors list (' + agentsList.length + ')'"
      action-refresh
      action-collapse
      @refresh="loadAgents"
    >
      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <download-excel
            v-if="$can('manage', 'all')"
            :data="agentsList"
            :fields="json_fields"
            worksheet="Active Sheet"
            name="agents.xls"
            style="background: #bad000;
          cursor: pointer;
          box-shadow: 2px 4px #e6e6e6;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px; text-align: center"
          >
            Export Excel
          </download-excel>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="agentsList"
        responsive
        :fields="$can('manage', 'all') ? adminFields : fields"
        primary-key="id"
        show-empty
        striped
        hover
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        empty-text="No matching records found"
      >

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.image"
                :text="avatarText(data.item.name)"
                :to="{ name: 'user_data', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'user_data', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap text-dark"
              style="text-decoration: underline"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.mobile }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(approved)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.approved)}`"
          >
            <feather-icon
              :icon="`${resolveUserStatusVariantIcon(data.item.approved)}`"
              class="mr-25"
            />
            <span>{{ resolveUserStatusVariantText(data.item.approved) }}</span>
          </b-badge>
        </template>

        <template #cell(isSignedUp)="data">
          <b-badge
            pill
            :variant="data.item.isSignedUp ? 'light-success' : 'light-danger'"
          >
            <feather-icon
              :icon="data.item.isSignedUp ? 'CheckIcon' : 'XIcon'"
              class="mr-25"
            />
            <span>{{ data.item.isSignedUp ? 'Yes' : 'No' }}</span>
          </b-badge>
        </template>

        <template #cell(agent.register)="data">
          <b-badge
            pill
            :variant="data.item.agent.register ? 'light-success' : 'light-danger'"
          >
            <feather-icon
              :icon="data.item.agent.register ? 'CheckIcon' : 'XIcon'"
              class="mr-25"
            />
            <span>{{ data.item.agent.register ? 'Yes' : 'No' }}</span>
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(approved)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.approved)}`"
          >
            <feather-icon
              :icon="`${resolveUserStatusVariantIcon(data.item.approved)}`"
              class="mr-25"
            />
            <span>{{ resolveUserStatusVariantText(data.item.approved) }}</span>
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="approveUserFunction(data.item.id, !data.item.approved)">
              <feather-icon :icon="data.item.approved ? 'XIcon' : 'CheckIcon'" />
              <span class="align-middle ml-50">{{ data.item.approved ? 'Disable' : 'Approve' }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteUserFunction(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="agentsList.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card-actions>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/extensions
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {
  BTable, BAvatar, BCol, BRow, BPagination, BLink, BMedia, BDropdown, BDropdownItem,
  BBadge, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BButton, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Agents',
  components: {
    BTable,
    BAvatar,
    BCol,
    BRow,
    BLink,
    BMedia,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardActions,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInput,
    BOverlay,
  },

  data() {
    return {
      perPage: 10,
      pageOptions: [5, 10, 15, 25, 50, 75, 100, 'All'],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      avatarText,
      filter: null,
      filterOn: [],
      json_fields: {
        Created: 'createdExcel',
        Name: 'name',
        Mobile: 'mobile',
        Email: 'email',
        Brokers: 'agent.brokersCountOnly',
        Ambassadors: 'agent.ambassadorsCountOnly',
        Total: 'agent.statistics.brokersCount',
        'Total Leads': 'agent.statistics.leadsCount',
        'Total Lives': 'agent.statistics.sum.lives',
        Premium: 'agent.statistics.sum.premium',
        'IS Signed up': 'isSignedUp',
        Status: 'approved',
      },
      fields: [
        { key: 'name', label: 'USER', sortable: true },
        { key: 'mobile', label: 'MOBILE', sortable: true },
        { key: 'email', label: 'EMAIL', sortable: true },
        { key: 'agent.brokersCountOnly', label: 'BROKERS', sortable: true },
        { key: 'agent.ambassadorsCountOnly', label: 'AMBASSADORS', sortable: true },
        { key: 'agent.statistics.brokersCount', label: 'TOTAL', sortable: true },
        { key: 'agent.statistics.leadsCount', label: 'LEADS', sortable: true },
        { key: 'agent.statistics.sum.lives', label: 'LIVES', sortable: true },
        { key: 'agent.statistics.sum.premium', label: 'PREMIUM', sortable: true },
        { key: 'approved', label: 'STATUS', sortable: true },
        { key: 'isSignedUp', label: 'IS SIGNED UP', sortable: true },
        { key: 'agent.register', label: 'IN REGISTER', sortable: true },
      ],
      adminFields: [
        { key: 'name', label: 'USER', sortable: true },
        { key: 'mobile', label: 'MOBILE', sortable: true },
        { key: 'email', label: 'EMAIL', sortable: true },
        { key: 'agent.brokersCountOnly', label: 'BROKERS', sortable: true },
        { key: 'agent.ambassadorsCountOnly', label: 'AMBASSADORS', sortable: true },
        { key: 'agent.statistics.brokersCount', label: 'TOTAL', sortable: true },
        { key: 'agent.statistics.leadsCount', label: 'LEADS', sortable: true },
        { key: 'agent.statistics.sum.lives', label: 'LIVES', sortable: true },
        { key: 'agent.statistics.sum.premium', label: 'PREMIUM', sortable: true },
        { key: 'approved', label: 'STATUS', sortable: true },
        { key: 'isSignedUp', label: 'IS SIGNED UP', sortable: true },
        { key: 'agent.register', label: 'IN REGISTER', sortable: true },
        { key: 'actions' },
      ],
      showLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      agentsList: 'users/agentsList',
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async created() {
    this.showLoading = true
    await this.loadAgents()
    this.showLoading = false
  },
  methods: {
    ...mapActions({
      loadAllAgents: 'get',
      approveUser: 'edit',
      deleteUser: 'delete',
    }),
    async loadAgents() {
      await this.loadAllAgents({
        storeName: 'users',
        url: '/admin/users/agents',
        commitUrl: 'users/SET_AGENTS',
      })
      this.$refs.agentsListActionCard.showLoading = false
    },
    async approveUserFunction(userID, approvedStatus) {
      await this.approveUser({
        form: { approved: approvedStatus, type: approvedStatus ? 'agent_approved' : '' },
        url: `admin/users/app/${userID}`,
        storeName: 'users',
      }).then(() => {
        this.loadAgents()
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: approvedStatus ? 'Sales Advisor Approved Successfully' : 'Sales Advisor Disabled Successfully',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error,
          },
        })
      })
    },
    async deleteUserFunction(userID) {
      await this.deleteUser({
        url: `admin/users/app/${userID}`,
        storeName: 'users',
      }).then(() => {
        this.loadAgents()
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Deleted Successfully',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error,
          },
        })
      })
    },
    resolveUserStatusVariant(status) {
      if (status === true) return 'success'
      if (status === false) return 'danger'
      return 'primary'
    },
    resolveUserStatusVariantText(status) {
      if (status === true) return 'Active'
      if (status === false) return 'Inactive'
      return 'Active'
    },
    resolveUserStatusVariantIcon(status) {
      if (status === true) return 'CheckIcon'
      if (status === false) return 'XIcon'
      return 'CheckIcon'
    },
  },
}
</script>

<style scoped>

</style>
